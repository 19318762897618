/*@import url('https://fonts.googleapis.com/css?family=Material+Icons');*/
@import "./styles/ProductWizard.css";

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/fonts/Material-Design-Iconic-Font.eot); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/fonts/Material-Design-Iconic-Font.woff2)
      format("woff2"),
    url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/fonts/Material-Design-Iconic-Font.woff)
      format("woff"),
    url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/fonts/Material-Design-Iconic-Font.ttf)
      format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

/* Toggle icon display 
.material-icons.show { display: block }
.material-icons.show { display: none }
*/

/* CUSTOMLY STYLED COMPONENTS/INPUTS */

/* CUSTOM CHECKBOXES */
.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 1px solid #e5e5e5;
}
/* .styled-checkbox:hover + label:before {
  background: #f35429;
} */
/* .styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
} */
.styled-checkbox:checked + label:before {
  /* background: #f35429; */
  background: var(--primary-color);
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: var(--badge-font);
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 var(--badge-font), 4px 0 0 var(--badge-font),
    4px -2px 0 var(--badge-font), 4px -4px 0 var(--badge-font),
    4px -6px 0 var(--badge-font), 4px -8px 0 var(--badge-font);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.styled-checkbox.loyaltyInput:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 11px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.profileCheckbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  min-height: 24px;
  width: 130px;
  clear: both;
}

.profileCheckbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.profileCheckbox-label .profileCheckbox-custom {
  position: absolute;
  top: 6px;
  left: 130px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 0;
  /* transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out; */ /* if we want animation for when is checked */
  border: 1px solid #e5e5e5;
}

.profileCheckbox-label input:checked ~ .profileCheckbox-custom {
  background-color: var(--primary-color);
  border-radius: 0;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 1px solid #e5e5e5;
}

.profileCheckbox-label .profileCheckbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 0;
  border: solid var(--buttons-font);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  /* transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out; */ /* if we want animation for when is checked */
}

.profileCheckbox-label input:checked ~ .profileCheckbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 6px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid var(--buttons-font);
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

.marketingCheckbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  min-height: 24px;
  width: 220px;
  clear: both;
}

.marketingCheckbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.marketingCheckbox-label .marketingCheckbox-custom {
  position: absolute;
  top: 6px;
  left: -22px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 0;
  /* transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out; */ /* if we want animation for when is checked */
  border: 1px solid #e5e5e5;
}

.marketingCheckbox-label input:checked ~ .marketingCheckbox-custom {
  background-color: var(--primary-color);
  border-radius: 0;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 1px solid #e5e5e5;
}

.marketingCheckbox-label .marketingCheckbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 0;
  border: solid var(--buttons-font);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  /* transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out; */ /* if we want animation for when is checked */
}

.marketingCheckbox-label input:checked ~ .marketingCheckbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 6px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid var(--buttons-font);
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#productSearch::placeholder {
  color: var(--primary-color);
}

/* RTL Arabic */

html[dir="rtl"] .styled-checkbox + label:before {
  margin-left: 10px;
  margin-right: 0;
}

html[dir="rtl"] .styled-checkbox:checked + label:after {
  right: 10px;
  left: 0;
}

html[dir="rtl"] .styled-checkbox.loyaltyInput:checked + label:after {
  right: 13px;
  left: 0;
}

html[dir="rtl"] .profileCheckbox-label .profileCheckbox-custom {
  right: 130px;
  left: 0;
}

html[dir="rtl"] .profileCheckbox-label .profileCheckbox-custom::after {
  right: 12px;
  left: 0;
}

html[dir="rtl"]
  .profileCheckbox-label
  input:checked
  ~ .profileCheckbox-custom::after {
  right: 6px;
  left: 0;
  border-width: 0 0 2px 2px;
}

html[dir="rtl"] .marketingCheckbox-label .marketingCheckbox-custom {
  right: -22px;
  left: 0;
}

html[dir="rtl"] .marketingCheckbox-label .marketingCheckbox-custom::after {
  right: 12px;
  left: 0;
}

html[dir="rtl"]
  .marketingCheckbox-label
  input:checked
  ~ .marketingCheckbox-custom::after {
  right: 6px;
  left: 0;
  border-width: 0 0 2px 2px;
}

html[dir="rtl"] .material-icons {
  transform: scaleX(-1);
}
