.background {
    /*background: #fffaf0;*/
    width: 100%;
    height: 100vh;
    position: relative;
    /*text-align: center;*/
    overflow: visible;
    display:flex;
    /*align-items: center;*/
}

.scanAnimation {
    width: 100%;
    height: 1px;
    background: #ffffff77;
    position: absolute;
    top: -60px;
    left: 0;
    box-shadow: 0 0 30px 30px #ffffff77;
    animation: move 1.5s infinite;
}

.appSplashImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: opacity 1.5s;
}

.appSplashImageIos{
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: opacity 1.5s;
}

.brand {
    padding: 10%;
    position: absolute;
    bottom: 50%;
    width: 100%;
    text-align: center;
}

.brandLogo {
    max-width: 144px;
    max-height: 144px;
    margin: 20px;
    animation: opacity 3s;
    position: relative;
}

.brandName {
    font-size: 1.7rem;
    -webkit-animation-name: fromRight; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
    animation-name: fromRight;
    animation-duration: 1s;
    position: relative;
}

.menuuLogo {
    position: absolute;
    width: 50%;
    max-width: 300px;
    bottom: 5%;
    left: 50%;
    margin: auto;
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: pulse 1s infinite;
}

.warningCriticalUpdate {
    background: #ffffffaa;
    box-shadow: 0 0 30px 30px #ffffffaa;
    text-align: center;
    animation: opacity 3s;
    padding: 0;
    position:absolute;
    top:50%;
    left: 50%;
    transform: translateX(-50%);
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    width: 280px;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
}
.warningCriticalUpdateText {
    font-size: 18px;
}

@-webkit-keyframes opacity{
    from{opacity:0}
    to{opacity:1}
}

@keyframes opacity{
    from{opacity:0}
    to{opacity:1}
}

@-webkit-keyframes pulse {
    0% {
        transform: translateX(-50%) scale(0.95);
    }
    70% {
        transform: translateX(-50%) scale(1.0);
    }
    100% {
        transform: translateX(-50%) scale(0.95);
    }
}
@keyframes pulse {
    0% {
        transform: translateX(-50%) scale(0.95);
    }
    70% {
        transform: translateX(-50%) scale(1.0);
    }
    100% {
        transform: translateX(-50%) scale(0.95);
    }
}

@keyframes move {
    0% { transform: translateY(0); }
    100% { transform: translateY(calc(100vh + 124px)); }
}