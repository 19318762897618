/* RestauranDetailsScreen */
.bannerImageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 250px;
  padding: 0;
  position: relative;
}

.bannerImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bannerImageOverlay {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 0 16px 10px 30px;
  /*padding: 0 25px 10px 25px;*/
  /*max-width: 90%;*/
  max-width: 100%;
  min-width: 320px;
  /* max-height: 45%; */
  max-height: 55%;
  position: absolute;
  bottom: -1px;
  background-color: #fff;
  opacity: 0.9;
}

.discountsAndMoreInfoContainer {
  display: flex;
  justify-content: center;
}

.availableDiscountsButton.pwa {
  margin: 0;
  z-index: 1;
}

.deliveryLabel {
  font-size: 13px;
  color: #a7a7a7;
  margin-right: 3px;
  font-style: italic;
}

.deliveryInfoLabel {
  font-size: 13px;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.availableDiscountsButton.pwa {
  width: 50%;
}

.availableDiscountsButton.pwa#moreInfo {
  border-left-color: #fff;
  border-left-width: 1px;
  border-left-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
}
.availableDiscountsButton.pwa#moreInfo span {
  display: flex;
  align-items: center;
}

#detailsScreenPwa {
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* MenusAndProductsScreen */

#restAndBrandName {
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* ShoppingCartMobile */
.pwaShoppingCart.btn.btn-secondary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 65px;
  bottom: 0;
  background-color: var(--primary-color);
  border-radius: 0;
  border: none;
  z-index: 3;
  font-size: 1.26em;
  left: 0;
}

.pwaShoppingCartInactive.btn.btn-secondary {
  /*display: flex;*/
  justify-content: space-between;
  align-items: center;
  position: fixed;
  /*width: 100%;*/
  /*height: 65px;*/
  bottom: 0;
  background-color: var(--inactive-color);
  border-radius: 0;
  border: none;
  z-index: 3;
  left: 0;
  /* Remove the shopping cart in pwa when there are no products selected */
  display: none;
  height: 0;
  width: 0;
}

.badgeStyle.badge-secondary {
  border-radius: 100%;
  background-color: #fff;
  color: #000;
  padding: 14px;
  width: 44px;
}

.firstCategory {
  margin-top: 0px !important;
}

/* RTL Arabic */

html[dir="rtl"] .bannerImageOverlay {
  padding: 0 30px 10px 16px;
}

html[dir="rtl"] .deliveryLabel {
  margin-right: 0px;
  margin-left: 3px;
}

html[dir="rtl"] .deliveryInfoLabel {
  margin-right: 0px;
  margin-left: 5px;
}

html[dir="rtl"] .availableDiscountsButton.pwa#moreInfo {
  border-right-color: #fff;
  border-right-width: 1px;
  border-right-style: solid;
  border-left: none;
}
