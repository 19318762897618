#contentContainer .restaurantsListContainer {
    margin-top: 20px!important;
}

#contentContainer .start-order-body-header-title.restaurantSelectionScreen {
    height: 0;
    display: none;
}

@media only screen and (min-width: 767px) {
    #contentContainer .startOrderSelectRestaurantContainer {
        margin: 0!important;
        display: flex!important;
        flex-direction: column!important;
        align-content: stretch!important;
        align-items: center!important;
        height: 100vh;
        overflow: hidden;
    }

    #contentContainer .restaurantsListContainer {
        min-width: 100%!important;
        max-height: 30vh;
        height: 30vh;
        overflow-y: hidden;
        overflow-x: hidden;
        padding: 0!important;
        z-index: 0!important;
        margin-bottom: 30px;
    }

    #contentContainer ul.restaurantsSelectionScreenUl {
        /*width: 70%;*/
        width: 95%;
        padding: 0;
        margin: 0 3%;
    }

    #contentContainer .mapContainer {
        min-width: 100%!important;
        height: 60vh;
        overflow: hidden;
        position: absolute;
        top: 40vh;
        margin: 0;
        width: 100%;
        max-width: 100%;
        z-index: 1;
    }

    #contentContainer #restaurantsMap {
        min-height: 600px;
        max-height: 100%;
        height: 100vh!important;
        width: 100%;
        min-width: 100%;
    }

    .selectedProductModal.inPwa {
        height: calc(100vh - 60px);
    }

    #pwaModalEmptyStep .selectedProductModal.inPwa.emptyStepModal {
        height: calc(100vh - 60px);
    }

    .modal-dialog.inPwa.selectedProductModalWithImage .modal-content .modalContentDiv {
        height: 50vh!important;
    }

    .modal-dialog.inPwa .modal-content .modalContentDiv {
        height: 90vh!important;
    }
}

/* Hiding the map on restaurant selection screen on mobile */
@media only screen and (max-width: 450px) {
    #restaurantSelectionMap {
        display: none;
    }

    .restaurantsListContainer {
        height: 98vh !important;
    }

    ul.restaurantsSelectionScreenUl {
        position: initial;
        width: 98%;
        max-height: 88%!important;
    }

    ul.restaurantsSelectionScreenUl::-webkit-scrollbar {
        display: none;
    }
}