#pwaMainScreenContainer {
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;
}

#infoMainContainer {
  width: 90%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(0, -100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  z-index: 11;
}

#addressInfoContainer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#addressInfoContainer ul li {
  background-color: #ffffff;
  padding: 20px 5px;
  margin-bottom: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  max-height: 35px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#pwaMainScreenMap {
  height: 100%;
  width: 100%;
  /*display: inline-block;*/
  z-index: 1;
}

#markerContainer {
  z-index: 2;
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
}

#editAddressIcon,
#gpsIcon {
  display: flex;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

#deliveryOptionButtonsContainer {
  width: 100%;
  z-index: 2;
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-direction: column;
}

#deliveryOptionButtonsContainer .orderTypeBtns {
  width: 90%;
  /* background-color: #ffffff; */
  background-color: var(--secondary-color);
  /* color: #62B522; */
  /* color: #ffffff; */
  /* opacity: 0.9; */
  padding: 10px 0;
  height: 55px;
  margin: 10px 5%;
  border: 1px solid #d8d8d8;
  cursor: pointer;
}

#deliveryOptionButtonsContainer .orderTypeBtns img {
  transform: scale(0.7);
}

.orderTypeBtns span {
  margin-left: 5px;
  font-size: 18px;
  position: relative;
  /*top: 3px;*/
}

#offlineMainScreenMapContainer {
  width: 100%;
  height: 100vh;
  position: relative;
}

#offlineMainScreenMapContainer h5 {
  position: absolute;
  top: 85px;
  left: 0;
  margin: 0;
  width: 100%;
  font-size: 15px;
  text-align: center;
  color: #ffffff;
}

#offlineMainScreenMapContainer h5 span {
  position: absolute;
  margin-left: 5px;
  animation: offline 1.4s infinite;
}

#offlineMainScreenMapContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* Geolocation animation */
#markerContainer.geolocationAnimation {
  animation: locatingUser 1.7s infinite;
}

@keyframes offline {
  50% {
    transform: translateY(-8px);
  }
}

@keyframes locatingUser {
  0% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    opacity: 0.6;
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
  }
}

/* RTL Arabic */

html[dir="rtl"] #infoMainContainer {
  right: 5%;
  left: 0;
}

html[dir="rtl"] .orderTypeBtns span {
  margin-left: 0px;
  margin-right: 5px;
}

html[dir="rtl"] #offlineMainScreenMapContainer h5 span {
  margin-left: 0px;
  margin-right: 5px;
}
