/* COMMON MODAL */

.commonModal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
}

.modalDialog {
    background: #fff;
    /* border-radius: 5px; */
    /* padding: 5px 0; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}



/* @media (min-width: 992px) {
    .modalDialog.productWizard {
        max-width: 1120px;
    }
} */