.drawerContent{
    width: 100%;
    height: 100vh;
    background :#fff;
    padding: 10px;
    background-color: var(--menu-items);
}

.drawerContent hr {
    border-color: #ccc;
}

.drawerLink{
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 7px;
    margin: 0 8px;
    color: var(--primary-font);
    border-bottom: 1px solid #ccc;
}
.inner .drawerLink:last-of-type{
    border: none;
}
.drawerLink:hover{
    text-decoration: none;
    color: var(--primary-color);
}

.drawerIcons{
    display: flex;
    align-items: center;
}

.drawerIcons i.material-icons{
    color: inherit;
    padding-right: 10px;
}

.poweredByMenuuLogo{
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 50%;
    margin: 0 25%;

}