.disableScroll {
    position: fixed;
    top: 0;
    left: 0;
}

/* Start Order Screens For PWA common styles */
#contentContainer .startOrderContentWrapper {
    padding-top: 5vh;
}

#contentContainer .confirmAddressButtonContainer,#contentContainer .modal-custom-body-footer.order-times-screen {
    position: fixed;
    top: 380px;
    left: 50%;
    width: 300px;
    transform: translate(-50%, 0);
}

#contentContainer .modal-custom-body-footer.order-times-screen {
    width: 100%!important;
    padding: 0!important;
    margin: 0;
}

#contentContainer .start-order-header-container.restaurantSelectionScreen, #contentContainer .start-order-header-container.orderTimeScreen {
    height: 0;
    display: none;
}

/* General Wizard Styles */
.generalWizardPwaModalContainer {
    margin: 0!important;
}

.generalWizardCloseButtonPwa {
    display: none!important;
}

/* Product Question Font is smaller and bolder */
h5.productWizardsQuestionName {
    font-size: 1.15rem!important;
    font-weight: 400!important;
    opacity: 0.7!important;
}

/* Styles for the nav bar svg icons in the pwa */
/* #backButtonSvgShadowed, #hamburgerIconSvgShadowed { */
    /* -webkit-filter: drop-shadow( 2px 2px 8px rgba(0, 0, 0, 0.4));
    filter: drop-shadow( 2px 2px 8px rgba(0, 0, 0, 0.4)); */
/* } */
