#applicationStep #headerContainer {
    z-index: 2;
}

/*Styling .modal.fade*/
#pwaModal {
    z-index: 1 !important;
    /*margin-top: 60px;*/
}

/* Styling modal-backdrop*/
#modalBackdrop {
    display: none;
    z-index: 1;
}

.modal-dialog.inPwa {
    min-height: calc(100% - 60px);
    width: 100%;
    /*width: 100vh!important;*/
    /*min-width: 100vh;*/
    /*max-width: 100vh;*/
    margin: 0;
    z-index: 1;
}

/* Style empty step modal differently */
.inPwa.emptyStepModal .modal-content{
    height: 100vh;
}

.selectedProductModalWithImage.inPwa.emptyStepModal .selectedProductModalHeader.emptyStepModal.modal-header {
    padding-top: 0;
}

.inPwa.emptyStepModal .modal-content .emptyStepModal {
    height: 69%;
    padding-top: 15px;
}

.inPwa.emptyStepModal .modal-content .emptyStepModal h5.modal-title img {
    /*object-fit: contain!important;*/
}

#pwaModalEmptyStep {
    z-index: 1;
    /*margin-top: 60px;*/
    overflow: hidden;
}

.modal-dialog.inPwa #customCloseBtn {
    height: 0;
    display: none;
}

/* Style Meal Deal modals */
.modal-dialog.inPwa.mealDealModalWithImage.modal-lg {
    max-width: fit-content!important;
}

.productDescriptionPwa {
    padding: 15px 0;
}

@media (min-width: 576px) {
    .modal-dialog.inPwa {
        min-width: 100%;
        margin: 0;
        z-index: 1;
    }

    .modal-dialog.inPwa .modal-content {
        height: 100vh;
    }

    .modal-dialog.inPwa .modal-content .modalContentDiv {
        height: 50vh;
    }

    .modal-dialog.inPwa .modal-content .modalContentDiv.mealDealHasPicture {
        height: 70vh!important;
    }

    .mealDealModalWithImage.inPwa .modalImageDiv {
        height: calc((100vh / 3) - 96px);
    }

    /* Empty step modal */
    .inPwa.emptyStepModal .modal-content .emptyStepModal {
        height: 80%;
    }

    .selectedProductModalWithImage.inPwa.emptyStepModal .selectedProductModalHeader.emptyStepModal {
        padding: 0;
    }

    .selectedProductModalWithImage.inPwa.emptyStepModal figure {
        height: 63vh!important;
    }

    .inPwa.emptyStepModal .modal-content .emptyStepModal h5.modal-title img {
        object-fit: contain!important;
    }
}