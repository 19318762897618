.confirmAddressButtonContainer .nextButton {
    display: inline-block!important;
    max-width: 300px;
    min-width: 300px;
    background-color: var(--primary-color);
}

.confirmAddressButtonContainer .nextButton.disabled {
    background-color: #4e4e4e!important;
}

#contentContainer .confirmAddressContainer {
    padding: 0!important;
    margin: 0!important;
}

#contentContainer .selectRestaurantForm.av-valid {
    max-height: calc(100% - 10px);
    overflow: hidden;
}

