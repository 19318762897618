#applicationStep {
  height: 100vh;
  width: 100%;
}

/* HEADER */
#headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  background-color: var(--primary-color);
  flex-grow: 1;
}

#headerContainerTransparent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  position: absolute;
  width: 100%;
  background-color: transparent;
  z-index: 2;
}

#headerContainerTransparent {
  width: 100vw !important;
  z-index: 3;
}

#headerContainerTransparent.sticky {
  position: fixed;
  background: var(--primary-color);
  opacity: 0.4;
  height: 50px;
  -webkit-transition: all ease-out 0.3s;
  -moz-transition: all ease-out 0.3s;
  -o-transition: all ease-out 0.3s;
  transition: all ease-out 0.3s;
}

#headerContainerTransparent.sticky-100 {
  position: fixed;
  background: var(--primary-color);
  opacity: 0.7;
  height: 50px;
  -webkit-transition: all ease-out 0.3s;
  -moz-transition: all ease-out 0.3s;
  -o-transition: all ease-out 0.3s;
  transition: all ease-out 0.3s;
}

#headerContainerTransparent.sticky-200 {
  position: fixed;
  background: var(--primary-color);
  opacity: 1;
  height: 50px;
  -webkit-transition: all ease-out 0.1s;
  -moz-transition: all ease-out 0.1s;
  -o-transition: all ease-out 0.1s;
  transition: all ease-out 0.1s;
}

#headerText {
  display: flex;
  flex-grow: 2;
  text-align: center;
  color: #fff;
  font-size: 1.3em;
  max-width: 80%;
  justify-content: center;
}

#drawerMenu {
  display: flex;
  justify-content: flex-end;
  max-width: 40px;
  padding-right: 10px;
  font-size: 24px;
  flex-grow: 1;
  color: #fff;
  z-index: 2;
}

/* CONTENT BODY */
#contentContainer {
  height: calc(100% - 60px);
  width: 100%;
  overflow: auto;
}

#contentContainerTransparent {
  height: 100%;
  width: 100%;
}

/* FOOTER */
