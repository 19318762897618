#contentContainer .modal-custom-body-title {
  display: flex;
  align-items: center;
}

#contentContainer .custom-input .input-group-prepend span.input-group-text {
  border: 1px solid var(--button-outline);
  border-right-width: 0;
  border-radius: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: var(--primary-color);
  background-color: var(--menu-items);
}

#contentContainer select.custom-input.form-control {
  border-top: 1px solid var(--button-outline);
  border-right: 1px solid var(--button-outline);
  border-bottom: 1px solid var(--button-outline);
  border-left: 1px solid transparent;
  margin-left: -4px;
  border-radius: 0px;
  /* border-top-left-radius: 0;
    border-bottom-left-radius: 0; */
  z-index: 2;
  background: transparent;
  -webkit-appearance: none;
}

#contentContainer .modal-body {
  min-height: auto !important;
  position: initial;
}

#contentContainer .modal-footer {
  border: none;
}

#contentContainer .modal-custom-body-footer .nextButton {
  justify-content: space-evenly;
  text-align: center;
  min-width: 300px;
  max-width: 300px;
}

#contentContainer .modal-custom-body-footer .disabledNextButton {
  display: flex;
  justify-content: center;
  min-width: 300px;
  max-width: 300px;
}

/* RTL Arabic */

html[dir="rtl"]
  #contentContainer
  .custom-input
  .input-group-prepend
  span.input-group-text {
  border: 1px solid var(--button-outline);
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

html[dir="rtl"] #contentContainer select.custom-input.form-control {
  border-left: 1px solid var(--button-outline);
  border-right: 1px solid transparent;
  margin-left: 0px;
  margin-right: -4px;
}
